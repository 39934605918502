<template>
    <div class="container">
        <message messageKey="messaggio-find" />
        <div class="row mt-5 text-center" v-if="!isLogged">
            <div
                v-if="
                    configs.login_pubblico && configs.login_pubblico.stato != 0
                "
            >
                <e-button
                    class="btn-lg mx-auto my-3 w-100"
                    @click="login"
                    :submit="false"
                    >{{ $t("login.accedi") }}</e-button
                >

                <p>{{ $t("manage.or_find") }}</p>
            </div>
            <div class="col-12 mt-5">
                <input
                    type="text"
                    aria-label="chiave_primaria"
                    name="chiave primaria"
                    v-model="chiave"
                    :placeholder="primaria"
                />
            </div>
            <div class="col-12">
                <input
                    name="codice_prenotazione"
                    aria-label="codice_prenotazione"
                    type="text"
                    v-model="codice"
                    :placeholder="$t('manage.find.code')"
                />
            </div>
            <p v-if="error" class="text-danger ml-2 mt-2">
                {{ $t("manage.find.not_found") }}
            </p>

            <div
                class="col-8 no-gutters mx-auto d-flex justify-content-center mt-5"
            >
                <e-button
                    @click="manage"
                    class="btn-lg mx-auto my-3 w-100"
                    :submit="false"
                    :disabled="disabled"
                    >{{ $t("gestisci.title") }}</e-button
                >
            </div>
        </div>

        <my-bookings
            v-else-if="configs.login_pubblico.stato != 0"
        ></my-bookings>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import MyBookings from "../MyBookings.vue";
import Message from "../../components/layout/Message";
export default {
    components: {
        MyBookings,
        Message
    },
    data() {
        return {
            chiave: "",
            codice: "",
            primaria: null,
            error: false,
            disabled: false
        };
    },

    computed: {
        ...mapState({
            errors: state => state.errors,
            configs: state => state.Ente.configs
        })
    },
    mounted() {
        this.getPrimaria();
    },
    methods: {
        ...mapMutations({
            setManage: "Manage/SET_MANAGE"
        }),
        ...mapActions({
            fetchManage: "Manage/fetchManage"
        }),
        async getPrimaria() {
            const res = await this.$http.get(
                `api/cliente/${this.cliente.id}/primaria`
            );
            this.primaria = res.data.label;
        },
        login() {
            localStorage.setItem("redirect", this.$route.name);
            this.$router.push({ name: "Login" });
        },
        manage() {
            this.disabled = true;
            this.fetchManage({ codice: this.codice, chiave: this.chiave })
                .then(() => {
                    this.$router.push({
                        name: "Show",
                        params: {
                            codice: this.codice
                        },
                        query: {
                            chiave: this.chiave
                        }
                    });
                })
                .catch(() => {
                    this.disabled = false;
                    this.error = true;
                });
        }
    }
};
</script>

<style>
.border-custom {
    border: 2px solid rgb(211, 211, 211);
    padding: 1rem;
}
</style>
